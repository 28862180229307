<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">ADI and Spec Grading Quiz</h2>

      <p class="mb-1">Match the ADI project steps to the partial description.</p>

      <p class="mb-6 pl-6">
        <v-row v-for="i in 7" :key="i.value" no-gutters class="mb-n3">
          <v-col class="pt-5" cols="3"> {{ adiPart[i - 1] }} </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="9">
            <v-select
              v-model="inputs.adiSteps[i - 1]"
              :items="adiStepsOptions"
              item-text="text"
              item-value="value"
              class="my-0"
              v-scroll
              label="Select an option:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        adiSteps: [],
      },
      adiPart: [
        'a) Introduction',
        'b) Guiding Questions',
        'c) Fundamental Skills',
        'd) Original Investigation',
        'e) Argumentation',
        'f) Lab Report',
        'g) Revision of Work',
      ],
      adiStepsOptions: [
        {text: 'learning from mistakes to improve future work', value: 'a'},
        {text: 'individual effort describing poster and argumentation results', value: 'b'},
        {text: 'step by step procedures given to students', value: 'c'},
        {text: 'background information', value: 'd'},
        {text: 'step by step procedures created by students', value: 'e'},
        {text: 'experiment objective', value: 'f'},
        {text: 'presentation & critique by lab mates', value: 'g'},
      ],
    };
  },
};
</script>
<style scoped></style>
